import React from "react"
import Layout from "../components/layout_products"
// import Hero from "../components/hero"
import content from "../../content/products.yaml"

export default () => (
  <>
  <Layout>
  <section id="products"
    style= {{
      padding: '10px 0 10px',
      background: '#F0F0F0'
    }}
  >
    <div className="row add-bottom">
    <div className="six columns add-bottom">
      <hr />
            { content.products.map((product, index) =>
            <div className="row add-bottom">

              <div>
                {/* <h5> { product.name } </h5> */}
                <p><img width="120" height="120" className="pull-left" alt={ product.name } src={ product.image } />
                     { product.body }
                </p>
                <p>
                   {product.name} { product.title } <p>Cooking instructions: { product.instructions }</p>
                </p>
              </div>
            </div>

          )
          }
    </div>
    </div>
  </section> 

</Layout>
  </>
)